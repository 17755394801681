import style from './Welcome.module.scss';

// Image
import welcomeLogo from '../../assets/img/welcome/logo.svg';

// Telegram
import { MainButton } from '@vkruglikov/react-telegram-web-app';


const Welcome = () => {
    return (
        <div className={style.welcome}>
            <div className={style.welcome__page}>
                <div className={style.welcome__page__logo}>
                    <img src={welcomeLogo} alt="welcome-logo" />
                </div>

                <div className={style.welcome__page__title}>
                    <p>Добро пожаловать в наш сервис онлайн консультаций с психологом випси (vipsy)</p>
                </div>
                <div className={style.welcome__page__description}>
                    <p>Психологи нашего сервиса помогут вам найти выход из любой ситуации, научат вас обходиться с негативными эмоциями и дадут совет.</p>
                </div>
            </div>

            {/* <div onClick={handleClickContinue} className={style.welcome_page__btn}>
                <ButtonMain text='Продолжить' />
            </div> */}
            {/* <ButtonMain text='Продолжить' /> */}
            <MainButton text='Продолжить' color='#8DE719' />
        </div>
    )
}

export default Welcome;