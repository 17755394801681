import './assets/css/App.scss';

// Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import Welcome from './pages/welcome/Welcome';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Welcome />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
